/* import __COLOCATED_TEMPLATE__ from './chart-action-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type Args } from 'ember-modifier/-private/class/modifier';
import type Chart from 'embercom/models/reporting/custom/chart';
import { inject as service } from '@ember/service';
import type ReportingCsvExport from 'embercom/services/reporting-csv-export';
import type IntlService from 'ember-intl/services/intl';
import type ReportingStandalone from 'embercom/services/reporting-standalone';
import type Report from 'embercom/models/reporting/custom/report';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
import { ActionType } from 'embercom/components/reporting/custom/chart-actions/chart-action-options';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type ReportAccessService from 'embercom/services/report-access-service';

interface DuplicateChartArgs {
  chartId: string;
  description: string;
}

interface Args {
  chart: Chart;
  report: Report;
  openUnderlyingDataModal: (chartSeries: ChartSeries) => void;
  editChart: (chartId: string) => void;
  duplicateChart: (args: DuplicateChartArgs) => void;
  deleteChart: (chartId: string) => void;
  isPaywalled: boolean;
  editMode: boolean;
  renderableChart: RenderableChart;
  isStandalone?: boolean;
}

const ACTIONS_IN_ORDER = [
  ActionType.DRILL_IN,
  ActionType.EDIT_CHART,
  ActionType.AGGREGATE_DATA,
  ActionType.DOWNLOAD_CSV_SOURCE,
  ActionType.DUPLICATE_CHART,
  ActionType.DELETE_CHART,
];

type Action = {
  name: string;
  showLabel: boolean;
  icon: InterfaceIconName;
  onClick: any;
  type: ActionType;
  tooltip: string | '';
  state?: {
    text: string;
    color: 'blue' | 'sky' | 'transparent' | 'green' | 'gray' | 'yellow-light' | 'yellow' | 'red';
  };
};

export default class ChartActionButtons extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare reportingCsvExport: ReportingCsvExport;
  @service declare reportingStandalone: ReportingStandalone;
  @service declare reportingChartService: ReportingChartService;
  @service declare customReportsService: CustomReportsService;
  @service declare reportAccessService: ReportAccessService;

  actionTypes = ActionType;

  get actionsMap(): Record<ActionType, Action> {
    // @ts-expect-error
    return {
      [ActionType.DRILL_IN]: {
        name: this.intl.t('components.reporting.custom.report.chart-actions.drill-in'),
        showLabel: true,
        icon: 'attribute-titles',
        onClick: () => this.args.openUnderlyingDataModal(this.firstChartSeries),
        type: ActionType.DRILL_IN,
        tooltip: '',
      },
      [ActionType.EDIT_CHART]: {
        name: this.intl.t('components.reporting.custom.report.chart-actions.edit'),
        showLabel: true,
        icon: 'edit',
        onClick: (chartId: string) => {
          if (this.customReportsService.canChangeCustomReports) {
            this.args.editChart(chartId);
          } else {
            this.customReportsService.loadChangePermissionModal();
          }
        },
        type: ActionType.EDIT_CHART,
        tooltip: this.intl.t('components.reporting.custom.report.chart-actions.edit-chart'),
      },
      [ActionType.DOWNLOAD_CSV_SOURCE]: {
        name: this.intl.t(
          'reporting.custom-reports.chart.explore-data.actions.export-conversations',
        ),
        showLabel: false,
        icon: 'filled-cloud-download',
        onClick: this.exportSourceDataAsCsv,
        type: ActionType.DOWNLOAD_CSV_SOURCE,
        tooltip: this.intl.t(
          'reporting.custom-reports.chart.explore-data.actions.export-conversations',
        ),
      },
      [ActionType.AGGREGATE_DATA]: {
        name: this.intl.t(
          'components.reporting.custom.report.chart-actions.export-aggregated-data',
        ),
        showLabel: false,
        icon: 'download',
        onClick: this.exportAggregatedDataAsCsv,
        type: ActionType.AGGREGATE_DATA,
        tooltip: this.intl.t(
          'components.reporting.custom.report.chart-actions.export-aggregated-data',
        ),
      },
      [ActionType.DUPLICATE_CHART]: {
        name: this.intl.t('components.reporting.custom.report.chart-actions.duplicate'),
        showLabel: false,
        icon: 'duplicate',
        onClick: () =>
          this.args.duplicateChart({
            chartId: this.args.chart.id,
            description: this.args.renderableChart.description,
          }),
        type: ActionType.DUPLICATE_CHART,
        tooltip: this.intl.t(
          'components.reporting.custom.report.draggable-chart-chrome.duplicate-chart',
        ),
      },
      [ActionType.DELETE_CHART]: {
        name: this.intl.t('components.reporting.custom.report.chart-actions.remove'),
        showLabel: false,
        icon: 'trash',
        onClick: () => this.args.deleteChart(this.args.chart.id),
        type: ActionType.DELETE_CHART,
        tooltip: this.intl.t('components.reporting.custom.report.chart-actions.remove-chart'),
      },
    };
  }

  get availableActions() {
    return this.reportingChartService
      .getAvailableActions(
        this.args.chart,
        this.args.report,
        this.args.isStandalone || false,
        this.args.isPaywalled,
        this.args.editMode || false,
        { isStatic: false },
      )
      .reject((action) => action === ActionType.VIEW_SOURCE);
  }

  get availableActionsInOrder() {
    return ACTIONS_IN_ORDER.filter((action) => this.availableActions.includes(action)).map(
      (action) => this.actionsMap[action],
    );
  }

  get numberOfButtonsToShow() {
    // show drill in and edit buttons if user can edit report or report is new
    return this.availableActions.includes(ActionType.EDIT_CHART) ? 2 : 1;
  }

  get buttonsToShow() {
    return this.availableActionsInOrder.slice(0, this.numberOfButtonsToShow);
  }

  get dropdownActions(): Action[] {
    return this.availableActionsInOrder.slice(this.numberOfButtonsToShow);
  }

  get firstChartSeries() {
    return this.args.chart.chartSeries.firstObject;
  }

  @action
  openUnderlyingDataModal(chartSeries: ChartSeries) {
    this.args.openUnderlyingDataModal(chartSeries);
  }

  @action
  async exportSourceDataAsCsv() {
    this.reportingCsvExport.exportChartData(this.args.chart, this.args.report);
  }

  @action
  async exportAggregatedDataAsCsv() {
    this.reportingCsvExport.exportChartDataPoints(
      this.args.renderableChart,
      this.args.chart,
      this.args.report,
    );
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartActions::ChartActionButtons': typeof ChartActionButtons;
    'reporting/custom/chart-actions-chart-action-buttons': typeof ChartActionButtons;
  }
}
