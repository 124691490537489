/* import __COLOCATED_TEMPLATE__ from './chart-actions-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Chart from 'embercom/models/reporting/custom/chart';
import type Report from 'embercom/models/reporting/custom/report';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type ReportingCsvExport from 'embercom/services/reporting-csv-export';
import type IntlService from 'ember-intl/services/intl';
import { ActionType } from 'embercom/components/reporting/custom/chart-actions/chart-action-options';
import type ReportingStandalone from 'embercom/services/reporting-standalone';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
interface Args {
  seeSourceData: () => void;
  chart: Chart;
  report: Report | null;
  reportState?: { isStatic?: boolean };
  renderableChart: RenderableChart;
  openUnderlyingDataModal: () => void;
  disabled?: boolean;
  closeActionDropdown: () => void;
  openActionDropdown: () => void;
  editChart?: (chartId: string) => void;
  duplicateChart?: (chartId: string, description: string) => void;
  deleteChart?: (chartId: string) => void;
  editMode?: boolean;
  isPaywalledCustomChart?: boolean;
  isStandalone?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [{ dropdownItems: Record<string, any>[] }];
  };
}
export default class ChartActionsDropdown extends Component<Signature> {
  @service declare appService: $TSFixMe;

  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare reportingCsvExport: ReportingCsvExport;
  @service declare reportingStandalone: ReportingStandalone;
  @service declare reportingChartService: ReportingChartService;

  get availableActions() {
    return this.reportingChartService.getAvailableActions(
      this.args.chart,
      this.args.report,
      this.args.isStandalone || false,
      this.args.isPaywalledCustomChart || false,
      this.args.editMode || false,
      this.args.reportState,
    );
  }

  get dataExplorationOptions() {
    let options: any[] = [];

    if (this.availableActions.includes(ActionType.DRILL_IN)) {
      options.push({
        name: this.intl.t('reporting.custom-reports.chart.see-underlying-data'),
        icon: 'attribute-titles',
        onClick: this.args.openUnderlyingDataModal,
        actionType: ActionType.DRILL_IN,
      });
    }

    if (this.availableActions.includes(ActionType.DOWNLOAD_CSV_SOURCE)) {
      options.push({
        name: this.intl.t(
          'reporting.custom-reports.chart.explore-data.actions.export-conversations',
        ),
        icon: 'conversation',
        onClick: this.downloadSourceDataAsCsv,
        state: 'legacy',
        tooltipText: this.intl.t('reporting.custom-reports.chart.export-modal.legacy-tooltip'),
      });
    }

    if (this.availableActions.includes(ActionType.AGGREGATE_DATA)) {
      options.push({
        name: this.intl.t('reporting.custom-reports.chart.explore-data.actions.export-chart-data'),
        icon: 'download',
        onClick: this.exportAggregatedDataAsCsv,
      });
    }

    return options;
  }

  get chartEditOptions() {
    let options: any[] = [];

    if (this.availableActions.includes(ActionType.EDIT_CHART)) {
      options.push({
        name: this.intl.t('components.reporting.custom.report.draggable-chart-chrome.actions.edit'),
        icon: 'edit',
        actionType: ActionType.EDIT_CHART,
        onClick: this.args.editChart,
      });
    }

    if (this.availableActions.includes(ActionType.DUPLICATE_CHART)) {
      options.push({
        name: this.intl.t(
          'components.reporting.custom.report.draggable-chart-chrome.duplicate-chart',
        ),
        icon: 'duplicate',
        actionType: ActionType.DUPLICATE_CHART,
        onClick: this.args.duplicateChart,
      });
    }

    if (this.availableActions.includes(ActionType.DELETE_CHART)) {
      options.push({
        name: this.intl.t(
          'components.reporting.custom.report.draggable-chart-chrome.actions.delete',
        ),
        icon: 'trash',
        actionType: ActionType.DELETE_CHART,
        onClick: this.args.deleteChart,
        classList: 'text-error',
      });
    }

    return options;
  }

  get groupList(): { heading?: string; items: any[] }[] {
    let dataExplorationOptions = this.dataExplorationOptions;
    let chartEditOptions = this.chartEditOptions;
    let explorationHeader = this.intl.t(
      'components.reporting.custom.report.draggable-chart-chrome.data-exploration',
    );
    let editHeader = this.intl.t(
      'components.reporting.custom.report.draggable-chart-chrome.edit-options',
    );

    let groups = [{ heading: explorationHeader, items: dataExplorationOptions }];

    if (chartEditOptions.length > 0) {
      groups.push({ heading: editHeader, items: chartEditOptions });
    }

    return groups;
  }

  @action
  async downloadSourceDataAsCsv() {
    this.reportingCsvExport.exportChartData(this.args.chart, this.args.report);
  }

  @action
  async exportAggregatedDataAsCsv() {
    this.reportingCsvExport.exportChartDataPoints(
      this.args.renderableChart,
      this.args.chart,
      this.args.report,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartActions::ChartActionsDropdown': typeof ChartActionsDropdown;
    'reporting/custom/chart-actions/chart-actions-dropdown': typeof ChartActionsDropdown;
  }
}
