/* import __COLOCATED_TEMPLATE__ from './highcharts-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import Highcharts, { type Options, type SeriesOptionsType, type Chart } from 'highcharts';
import { initializeHighcharts } from 'embercom/components/reporting/flexible/highcharts';
import { modifier } from 'ember-modifier';

initializeHighcharts();

export interface Signature {
  Args: {
    highchartsOptions: Options;
    highchartsSeries: SeriesOptionsType[];
  };
  Blocks: {
    default: [];
  };
}

export default class HighchartsWrapper extends Component<Signature> {
  chart?: Chart;

  createHighchartsChart = modifier(
    (element: HTMLElement) => {
      // not sure why these have been split into two args and merged here, but it's easier to leave it like that
      let options = {
        ...this.args.highchartsOptions,
        plotOptions: {
          ...this.args.highchartsOptions?.plotOptions,
          series: {
            borderWidth: 0,
            ...this.args.highchartsOptions?.plotOptions?.series,
          },
        },
        series: this.args.highchartsSeries,
      };
      if (this.chart) {
        // If we want to take advantage of any performance / animation improvements then we'll have to revisit
        // how we show loading states (e.g. use a spinner overlay while still showing the chart)

        // Calling `this.chart.update(options)` does work, but not always as you would expect. Highcharts doesn't
        // detect when series are removed and maybe more, so technically you could end up with ghost series in
        // the Reporting Chart Builder etc. when stuff changes.
        //
        // Needs more work so for now let's continue to just destroy and recreate

        // Anyway, in practice, we're not going to run this code because we show a spinner instead
        // of this component when we're loading data. Which will destroy everything.
        this.chart.destroy();
      }
      this.chart = Highcharts.chart(element, options);
      this.workaroundSankeyLayoutBug();
    },
    { eager: false },
  );

  workaroundSankeyLayoutBug() {
    // see https://stackoverflow.com/questions/70267309/highcharts-sankey-diagram-node-rearrangement
    if (this.chart && this.chart.series.some((s) => s.type === 'sankey')) {
      this.chart.redraw();
    }
  }

  @action
  hideActiveTooltips() {
    let tooltip = this.chart?.tooltip as any;
    if (tooltip && tooltip.isHidden) {
      tooltip.hide(0);
    }
  }

  willDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
    super.willDestroy();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::HighchartsWrapper': typeof HighchartsWrapper;
    'reporting/flexible/highcharts-wrapper': typeof HighchartsWrapper;
  }
}
