/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { assign } from '@ember/polyfills';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import BaseChart from 'embercom/components/reporting/base-chart';
import { stack } from 'd3-shape';
import { scaleLinear, scaleBand, scaleOrdinal } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { extent } from 'd3-array';
import moment from 'moment-timezone';
import { CONVERSATION_RATINGS } from 'predicates/models/data/conversation-ratings';
import PALETTE from '@intercom/pulse/lib/palette';
import { color } from 'd3-color';
import { select } from 'd3-selection';
import { makeDateFormatter } from 'embercom/lib/reporting/date-formatter';

export default BaseChart.extend({
  'data-test-reporting-stacked-bar-chart': true,
  classNames: ['reporting__chart__stacked-bar-chart'],

  intl: service(),

  redrawChart() {
    // this chart doesn't support re-drawing
    // instead just nuke everything and start from scratch
    if (this.element.getElementsByClassName('y axis').length) {
      this.element.removeChild(this.element.childNodes[0]);
      this._drawChart();
      return;
    }

    let chart = this.chart();
    let chartHeight = this.chartHeight;
    let xScale = scaleLinear().rangeRound([0, this.chartWidth]);
    let yScale = scaleBand().rangeRound([chartHeight, 0]).padding(0.1);
    let colors = scaleOrdinal([
      PALETTE['vis-green-40'],
      PALETTE['vis-green-60'],
      PALETTE['vis-yellow-70'],
      PALETTE['vis-yellow-50'],
      PALETTE['vis-salmon-40'],
    ]);
    let data = this.get('signal.signalAsStackablePercentageData');
    let dates = data.map((d) => new Date(d.date));

    let xAxis = axisBottom(xScale)
      .ticks(5)
      .tickFormat((d) => `${d}%`);

    let dateExtent = extent(dates);
    let rangeInDays = Math.round(moment.duration(dateExtent[1] - dateExtent[0]).asDays());
    let isRangeHours = rangeInDays <= 1;
    let isRangeDays = rangeInDays <= 178;
    let heightOfAxisLabel = 15;
    let maxNumberOfTicks = chartHeight / heightOfAxisLabel;
    let yAxisStride = Math.ceil(dates.length / maxNumberOfTicks);

    let dateFormat = makeDateFormatter({
      stride: yAxisStride,
      isRangeHours,
      isRangeDays,
    });

    let yAxis = axisLeft(yScale).tickFormat((item, i) => {
      let format = dateFormat(item, i);
      return isEmpty(format) ? '' : this.intl.formatDate(item, { format, timeZone: this.timezone });
    });

    let s = stack().keys([5, 4, 3, 2, 1]);

    let layers = s(data);
    yScale.domain(dates);
    xScale.domain([0, 100]);

    let layer = chart
      .selectAll('.reporting__chart__stack-layer')
      .data(layers)
      .enter()
      .append('g')
      .classed('reporting__chart__stack-layer', true)
      .style('fill', function (d, i) {
        return colors(i);
      });

    layer
      .selectAll('rect')
      .data(function (d) {
        // By default D3 doesn't include the index in its returned
        // object, only the original data object. We create a custom
        // object and include the index.
        return d.map(function (elem) {
          return assign(elem, { index: d.index });
        });
      })
      .enter()
      .append('rect')
      .classed('js__legacy-reporting-tooltip', true)
      .classed('reporting__chart__transition', true)
      .attr('data-component', 'tooltips/reporting-stacked-bar-datum')
      .attr('data-percentage', (d) => d.data.roundedPercentagesPerRating[5 - d.index] || 0) // The index starts at 0 which corresponds to the highest rating (5)
      .attr('data-conversations', function (d) {
        return d.data.totalConversations;
      })
      .attr('data-range-start', this.get('range.start'))
      .attr('data-range-end', this.get('range.end'))
      .attr('data-aggregation-interval', this.get('range.interval'))
      .attr('data-total-conversations-per-rating', (d) => d.data.totalsPerRating[5 - d.index]) // The index starts at 0 which corresponds to the highest rating (5)
      .attr('data-date', function (d) {
        return d.data.date;
      })
      .attr('data-emoji-value', function (d) {
        return CONVERSATION_RATINGS[4 - d.index].value;
      }) // Reverse index when using to look up emoji value
      .attr('y', function (d) {
        return yScale(new Date(d.data.date));
      })
      .attr('x', function (d) {
        return xScale(d[0]);
      })
      .attr('height', yScale.bandwidth())
      .attr('width', function (d) {
        let width = xScale(d[1]) - xScale(d[0]);
        let emptyStateWidth = 0;
        return width || emptyStateWidth;
      })
      .on('mouseover', function (d, i) {
        select(this).style('fill', color(colors(d.index)));
      })
      .on('mouseout', function (d, i) {
        select(this).style('fill', colors(d.index));
      });

    chart
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${chartHeight})`)
      .call(xAxis);

    chart.append('g').attr('class', 'y axis').call(yAxis);

    chart.selectAll('.tick text').classed('reporting__chart__axis-label', true);
  },
});
