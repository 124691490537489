/* import __COLOCATED_TEMPLATE__ from './edit-mode.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import HeaderComponent from 'embercom/components/series/header';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import {
  SeriesAnalyticTracker,
  SeriesAnalyticActions,
  SeriesAnalyticObjects,
} from 'embercom/lib/series/analytic-tracker';

const MODAL_STATES = {
  closed: 0,
  openedFromButton: 1,
  openedFromShortcut: 2,
};
export default class EditMode extends HeaderComponent {
  @service appService;
  @service seriesEditorService;
  @service notificationsService;
  @service permissionsService;
  @service intercomEventService;
  @tracked modalState = MODAL_STATES.closed;
  @tracked showDuplicateIntoSeriesModal = false;
  @tracked showDeleteSeriesModal = false;
  @tracked showCsvExportModal = false;
  @tracked showTaggingModal = false;

  get isCsvExportDisabled() {
    let seriesReceiptStats = this.seriesEditorService.series.stats.findBy(
      'key',
      statisticKeys.receipts,
    );
    return !(seriesReceiptStats && seriesReceiptStats.value);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.seriesEditorService.isEditingTitle = false;
  }

  @action
  openDeleteSeriesModal() {
    this.showDeleteSeriesModal = true;
  }

  @action
  closeDeleteSeriesModal() {
    this.showDeleteSeriesModal = false;
  }

  @action
  onDuplicateRulesetModalOpened() {
    this.seriesEditorService.isDuplicatingNode = true;
  }

  @action
  onDuplicateRulesetModalClosed() {
    this.showDuplicateIntoSeriesModal = false;
    this.seriesEditorService.isDuplicatingNode = false;
  }

  @action
  openSettings() {
    this.seriesEditorService.storeSettingsState();
    this.seriesEditorService.updateIsShowingSettings(true);
  }

  get showSaveModal() {
    return (
      this.modalState === MODAL_STATES.openedFromButton ||
      this.modalState === MODAL_STATES.openedFromShortcut
    );
  }

  @action openModalFromButton() {
    this.modalState = MODAL_STATES.openedFromButton;
  }

  @action closeModal() {
    this.modalState = MODAL_STATES.closed;
  }

  @action
  confirmSave() {
    if (this.modalState === MODAL_STATES.openedFromShortcut) {
      this.seriesEditorService.save.perform().catch((err) =>
        this.notificationsService.notifyResponseError(err, {
          default: 'Error saving the Series',
        }),
      );
    } else {
      this.seriesEditorService.saveAndClose.perform().catch((err) =>
        this.notificationsService.notifyResponseError(err, {
          default: 'Error saving the Series',
        }),
      );
    }
    this.closeModal();
    new SeriesAnalyticTracker({
      intercomEventService: this.intercomEventService,
      seriesEditorService: this.seriesEditorService,
    }).track({ action: SeriesAnalyticActions.clicked, object: SeriesAnalyticObjects.saveButton });
  }

  @action
  showCsvExport() {
    let hasPermission = this.permissionsService.currentAdminCan('can_export_outbound_data');
    if (hasPermission) {
      this.showCsvExportModal = true;
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_export_outbound_data',
      );
    }
  }

  @action
  onKeyDown() {
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      this.onSaveShortcut(event);
    }
  }

  onSaveShortcut(event) {
    event.preventDefault();
    if (!this.seriesEditorService.activeSeriesNode && this.seriesEditorService.isEditMode) {
      if (this.seriesEditorService.series.isLive) {
        this.modalState = MODAL_STATES.openedFromShortcut;
      } else {
        this.seriesEditorService.save.perform();
      }
    }
  }
}
