/* import __COLOCATED_TEMPLATE__ from './sankey-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import SankeychartBuilder from 'embercom/lib/reporting/flexible/sankeychart-builder';
import { inject as service } from '@ember/service';
import { REPORTS_PRECISION_DEFAULT, toPercentString } from 'embercom/lib/percentage-formatter';
import countFormatter from 'embercom/lib/count-formatter';
import moment from 'moment-timezone';
import { copy } from 'ember-copy';
import PALETTE from '@intercom/pulse/lib/palette';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  buildProactiveMessagesQuery,
  DROPOFFS,
  SELF_SERVE_FAILED,
  SELF_SERVE_RESOLVED,
  SERVED_BY_HUMAN,
} from 'embercom/lib/reporting/csf-queries';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import {
  formattedRangeText,
  getTimeConfig,
  signalHasImproved,
} from 'embercom/lib/reporting/csf-query-helper';
import { buildDataConfigWithComparisons } from 'embercom/lib/reporting/comparison-data-config';
import { dasherize } from '@ember/string';
import Highcharts from 'highcharts';
import ConversationalSupportFunnelComparisonTooltipTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/comparison-tooltip';
import humanSupportDirectEngagedLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/human-support-direct-engaged';
import proactiveLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/proactive';
import seekingSupportLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/seeking-support';
import selfServeEngagedLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/self-serve-engaged';
import selfServeFailedFakeNodeLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/self-serve-failed-fake-node';
import selfServePercentageLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/self-serve-percentage';
import selfServeResolvedLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/self-serve-resolved';
import servedByHumanLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/served-by-human';
import testLabelTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/labels/test';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';

const gapBetweenTwoLevels = 75;
const nodeWidth = 5;

const PROACTIVE_MESSAGES_COUNT = 'sankey-proactive-receipts-count';

const colors = {
  SEEKING_SUPPORT_EDGE: PALETTE['neutral-container'],
  PROACTIVE: PALETTE['vis-turquoise-50'],

  SELF_SERVE_NODE: PALETTE['vis-pink-50'],
  SELF_SERVE_LINK: PALETTE['vis-pink-80'],
  SELF_SERVE_LABEL: PALETTE['vis-pink-40'],
  SELF_SERVE_HOVER: PALETTE['vis-pink-70'],

  HUMAN_SUPPORT_NODE: PALETTE['vis-yellow-50'],
  HUMAN_SUPPORT_LINK: PALETTE['vis-yellow-80'],
  HUMAN_SUPPORT_LABEL: PALETTE['vis-yellow-40'],
  HUMAN_SUPPORT_HOVER: PALETTE['vis-yellow-70'],

  DEFAULT_LABEL: PALETTE['text-default'],
  BACKGROUND: PALETTE['base-module'],
};

const tooltipTexts = {
  SELF_SERVE_ENGAGED: 'tried to resolve with self-serve content',
  SELF_SERVE_RESOLVED: 'resolved with self-serve content',
  HUMAN_SUPPORT_DIRECT_ENGAGED: 'served by a teammate from the beginning',
  SELF_SERVE_FAILED: 'served by a teammate after self-serve',
  SEEKING_SUPPORT: 'seeking support',
  SERVED_BY_HUMAN: 'served by human support',
  PROACTIVE: 'proactive message delivered',
  PROACTIVE_PLURALIZED: 'proactive messages delivered',
};

const LABEL_TEMPLATES = {
  humanSupportDirectEngaged: humanSupportDirectEngagedLabelTemplate,
  proactive: proactiveLabelTemplate,
  seekingSupport: seekingSupportLabelTemplate,
  selfServeEngaged: selfServeEngagedLabelTemplate,
  selfServeFailedFakeNode: selfServeFailedFakeNodeLabelTemplate,
  selfServePercentage: selfServePercentageLabelTemplate,
  selfServeResolved: selfServeResolvedLabelTemplate,
  servedByHuman: servedByHumanLabelTemplate,
  test: testLabelTemplate,
};

const dateBeforeIngestion = moment('2021-10-06');

const nodes = {
  PROACTIVE: {
    id: 'proactive',
    color: colors.BACKGROUND,
    labelColor: colors.PROACTIVE,
    tooltipText: tooltipTexts.PROACTIVE,
    pluralizedTooltipText: tooltipTexts.PROACTIVE_PLURALIZED,
    improvementDirection: improvementDirections.INCREASING,
    level: 0,
    eventContext: 'proactive',
  },
  SEEKING_SUPPORT: {
    id: 'seekingSupport',
    color: colors.SEEKING_SUPPORT_EDGE,
    labelColor: colors.DEFAULT_LABEL,
    tooltipText: tooltipTexts.SEEKING_SUPPORT,
    improvementDirection: improvementDirections.INCREASING,
    level: 1,
    eventContext: 'seeking_support',
  },
  SELF_SERVE_ENGAGED: {
    id: 'selfServeEngaged',
    color: colors.SELF_SERVE_NODE,
    level: 2,
    tooltipText: tooltipTexts.SELF_SERVE_ENGAGED,
    improvementDirection: improvementDirections.INCREASING,
    eventContext: 'tried_to_self_serve',
  },
  SELF_SERVE_RESOLVED: {
    id: 'selfServeResolved',
    color: colors.SELF_SERVE_NODE,
    level: 3,
    pushToTop: true,
    tooltipText: tooltipTexts.SELF_SERVE_RESOLVED,
    improvementDirection: improvementDirections.INCREASING,
    eventContext: 'resolved_by_self_serve_support',
  },
  SELF_SERVE_FAILED: {
    id: 'selfServeFailed',
    color: colors.HUMAN_SUPPORT_NODE,
    level: 3,
    tooltipText: tooltipTexts.SELF_SERVE_FAILED,
    improvementDirection: improvementDirections.DECREASING,
    eventContext: 'served_by_teammate_after_self_serve',
  },
  SELF_SERVE_FAKE_NODE: {
    id: 'selfServeFakeNode',
    originalNodeId: `selfServeResolved`,
    color: colors.SELF_SERVE_LINK,
    normalColor: colors.SELF_SERVE_LINK,
    hoverColor: colors.SELF_SERVE_HOVER,
    level: 4,
    tooltipText: tooltipTexts.SELF_SERVE_RESOLVED,
    improvementDirection: improvementDirections.INCREASING,
    hoverGroup: 2,
    eventContext: 'resolved_by_self_serve_support',
  },
  SELF_SERVE_FAILED_FAKE_NODE: {
    id: 'selfServeFailedFakeNode',
    originalNodeId: `selfServeFailed`,
    color: colors.HUMAN_SUPPORT_LINK,
    normalColor: colors.HUMAN_SUPPORT_LINK,
    hoverColor: colors.HUMAN_SUPPORT_HOVER,
    level: 4,
    offset: gapBetweenTwoLevels,
    extraCurves: true,
    tooltipText: tooltipTexts.SELF_SERVE_FAILED,
    improvementDirection: improvementDirections.DECREASING,
    hoverGroup: 3,
    eventContext: 'served_by_teammate_after_self_serve',
  },
  SELF_SERVE_PERCENTAGE: {
    id: 'selfServePercentage',
    originalNodeId: 'selfServeResolved',
    color: colors.SELF_SERVE_NODE,
    level: 5,
    tooltipText: tooltipTexts.SELF_SERVE_RESOLVED,
    improvementDirection: improvementDirections.INCREASING,
    eventContext: 'resolved_by_self_serve_support',
  },
  HUMAN_SUPPORT_DIRECT_ENGAGED: {
    id: 'humanSupportDirectEngaged',
    color: colors.HUMAN_SUPPORT_NODE,
    level: 2,
    tooltipText: tooltipTexts.HUMAN_SUPPORT_DIRECT_ENGAGED,
    improvementDirection: improvementDirections.DECREASING,
    eventContext: 'served_by_teammate_from_the_beginning',
  },
  HUMAN_SUPPORT_FAKE_NODE: {
    id: 'humanSupportFakeNode',
    originalNodeId: 'humanSupportDirectEngaged',
    color: colors.HUMAN_SUPPORT_LINK,
    normalColor: colors.HUMAN_SUPPORT_LINK,
    hoverColor: colors.HUMAN_SUPPORT_HOVER,
    level: 4,
    offset: gapBetweenTwoLevels + 3,
    extraCurves: true,
    tooltipText: tooltipTexts.HUMAN_SUPPORT_DIRECT_ENGAGED,
    improvementDirection: improvementDirections.DECREASING,
    hoverGroup: 4,
    eventContext: 'served_by_teammate_from_the_beginning',
  },
  SERVED_BY_HUMAN: {
    id: 'servedByHuman',
    color: colors.HUMAN_SUPPORT_NODE,
    level: 5,
    offset: gapBetweenTwoLevels,
    tooltipText: tooltipTexts.SERVED_BY_HUMAN,
    improvementDirection: improvementDirections.DECREASING,
    eventContext: 'served_by_human_support',
  },
};

export default class SankeyChart extends Component {
  @service appService;
  @service intercomEventService;
  @tracked dropoffs;

  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.dataConfig,
    viewConfig: this.args.viewConfig,
  }));

  constructor() {
    super(...arguments);
    this._allowHighchartsAttributes();
  }

  _allowHighchartsAttributes() {
    // From Highcharts 9.x you need to specifically opt in any custom data- attributes
    Object.values(nodes).forEach((node) => {
      Highcharts.AST.allowedAttributes.push(`data-test-label-${dasherize(node.id)}`);
    });
    Highcharts.AST.allowedAttributes.push('data-test-label-human-support-percentage');
    Highcharts.AST.allowedAttributes.push('data-test-csf-comparison-tooltip');
    Highcharts.AST.allowedAttributes.push('data-test-csf-comparison-tooltip-arrow');
  }

  analytics = {
    place: 'csf_report',
  };

  get app() {
    return this.appService.app;
  }

  get time() {
    return getTimeConfig({ range: this.args.range });
  }

  previousName(name) {
    return `${name}_previous`;
  }

  get dataConfig() {
    let config = {
      series: [
        {
          name: nodes.SELF_SERVE_FAILED.id,
          ...SELF_SERVE_FAILED,
          time: this.time,
        },
        {
          name: nodes.SELF_SERVE_RESOLVED.id,
          ...SELF_SERVE_RESOLVED,
          time: this.time,
        },
        {
          name: nodes.SERVED_BY_HUMAN.id,
          ...SERVED_BY_HUMAN,
          time: this.time,
        },
      ],
    };

    if (this.args.isProactiveSetupDone) {
      let proactiveQuery = buildProactiveMessagesQuery(
        PROACTIVE_MESSAGES_COUNT,
        ['stats_receipt'],
        this.args.contentIds,
        this.args.range,
      );
      config.series.push(proactiveQuery);
    }

    let configWithComparisons = buildDataConfigWithComparisons(config, this.args.range);

    configWithComparisons.series.push({
      name: 'dropoffs',
      ...DROPOFFS,
      time: this.time,
    });

    return configWithComparisons;
  }

  formatNode(customContext) {
    let template = LABEL_TEMPLATES[this.point.id];
    if (template) {
      let context = {
        humanSupportPercentage: toPercentString(
          customContext.humanSupportPercentage,
          REPORTS_PRECISION_DEFAULT,
        ),
        selfServePercentage: toPercentString(
          customContext.selfServePercentage,
          REPORTS_PRECISION_DEFAULT,
        ),
        proactives: countFormatter(customContext.proactives),
        value: countFormatter(this.point.sum),
        color: this.point?.labelColor || this.point.color,
      };
      return template(context);
    }
  }

  get hasData() {
    return !!this.chartData;
  }

  formatComparisonTooltip() {
    let rangeText = formattedRangeText(this.args.range);
    let signalHasImprovedFn = signalHasImproved();
    let calculateValue = this._calculateValue();
    let calculatePreviousValue = this._calculatePreviousValue();
    let mapTooltipText = this._mapTooltipText();

    return function () {
      if (this.point.tooltipText) {
        let value = calculateValue(this.point);
        let previousValue = calculatePreviousValue(this.point);
        let improvementDirection =
          this.point.improvementDirection ||
          this.point.linksFrom?.firstObject?.improvementDirection;
        let delta = Math.abs(value - previousValue);
        let signalHasIncreased = value > previousValue;

        let context = {
          currentValue: countFormatter(value),
          previousValue: countFormatter(previousValue),
          delta,
          deltaText: countFormatter(delta),
          signalHasIncreased,
          signalHasImproved: signalHasImprovedFn(signalHasIncreased, improvementDirection),
          rangeText,
          text: mapTooltipText(this.point, value),
        };
        return ConversationalSupportFunnelComparisonTooltipTemplate(context);
      }

      // This is necessary to override the old tooltip formatter
      return false;
    };
  }

  _calculateValue() {
    return function (point) {
      if (point.id === nodes.PROACTIVE.id) {
        return point.actualValue;
      }

      return point.sum || point.fromNode.sum;
    };
  }

  _calculatePreviousValue() {
    return function (point) {
      if (point.previousValue !== undefined) {
        return point.previousValue;
      }
      return point.fromNode.previousValue;
    };
  }

  _mapTooltipText() {
    return function (point, value) {
      if (point.pluralizedTooltipText && value !== 1) {
        return point.pluralizedTooltipText;
      }
      return point.tooltipText;
    };
  }

  getCountsFromDataResponses(dataResponses) {
    let counts = {};
    for (let dataResponse of dataResponses) {
      counts[dataResponse.name] = dataResponse.aggregations[0].values[0];
    }
    return this.buildTotalCounts(counts);
  }

  @cached
  get chartData() {
    let counts = this.getCountsFromDataResponses(this.dataResource.rawChartData);
    // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
    // eslint-disable-next-line
    this.dropoffs = countFormatter(counts.dropoffs);

    if (
      counts.seekingSupport === 0 &&
      counts.selfServeEngaged === 0 &&
      counts.selfServeResolved === 0
    ) {
      return null;
    }

    let selfServePercentage = (100 * counts.selfServeResolved) / counts.seekingSupport;
    let humanSupportPercentage = 100 - selfServePercentage;

    let self = this;

    return [
      {
        states: { inactive: { enabled: false } },
        levels: [
          {
            level: this.levelForSankey,
          },
        ],
        data: this.getDataForSankey(counts),
        type: 'sankey',
        nodePadding: 0,
        nodeWidth,
        minLinkWidth: 3,
        dataLabels: {
          nodeFormatter() {
            return self.formatNode.bind(this)({
              humanSupportPercentage,
              selfServePercentage,
              proactives: counts.proactive,
            });
          },
          color: PALETTE['text-default'],
          style: { textOutline: 'none' },
          useHTML: true,
          allowOverlap: true,
          align: 'left',
          padding: 0,
        },
        nodes: this.getNodesForSankey(counts),
        clip: false,
      },
    ];
  }

  buildTotalCounts(counts) {
    let selfServeResolved = counts[nodes.SELF_SERVE_RESOLVED.id];
    let selfServeResolved_previous = counts[this.previousName(nodes.SELF_SERVE_RESOLVED.id)];
    let servedByHuman = counts[nodes.SERVED_BY_HUMAN.id];
    let servedByHuman_previous = counts[this.previousName(nodes.SERVED_BY_HUMAN.id)];
    let selfServeFailed = counts[nodes.SELF_SERVE_FAILED.id];
    let selfServeEngaged = selfServeResolved + selfServeFailed;
    let selfServeFailed_previous = counts[this.previousName(nodes.SELF_SERVE_FAILED.id)];
    let selfServeEngaged_previous = selfServeFailed_previous + selfServeResolved_previous;
    let seekingSupport = selfServeResolved + servedByHuman;
    let seekingSupport_previous = selfServeResolved_previous + servedByHuman_previous;

    return {
      selfServeEngaged,
      selfServeEngaged_previous,
      selfServeResolved,
      selfServeResolved_previous,
      servedByHuman,
      servedByHuman_previous,
      dropoffs: counts['dropoffs'],
      proactive: counts[PROACTIVE_MESSAGES_COUNT],
      proactive_previous: counts[this.previousName(PROACTIVE_MESSAGES_COUNT)],
      seekingSupport,
      seekingSupport_previous,
      selfServeFailed,
      selfServeFailed_previous,
      humanSupportDirectEngaged: seekingSupport - selfServeEngaged,
      humanSupportDirectEngaged_previous: seekingSupport_previous - selfServeEngaged_previous,
    };
  }

  get dateFilterBeforeIngestion() {
    return this.args.range.startMoment < dateBeforeIngestion;
  }

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.args.viewConfig,
      dataConfig: this.dataConfig,
      spacingBottom: this.spacingBottom,
      tooltipFormatter: this.formatComparisonTooltip(),
      trackAnalyticsEvent: this.trackAnalyticsEvent,
    };

    let builder = new SankeychartBuilder(options);
    return builder.buildTheme();
  }

  getDataForSankey(counts) {
    let data = [
      {
        from: nodes.PROACTIVE.id,
        to: nodes.SEEKING_SUPPORT.id,
        weight: counts.seekingSupport,
        color: colors.BACKGROUND,
        improvementDirection: improvementDirections.INCREASING,
      },
      {
        from: nodes.SEEKING_SUPPORT.id,
        to: nodes.SELF_SERVE_ENGAGED.id,
        weight: counts.selfServeEngaged,
        tooltipText: tooltipTexts.SEEKING_SUPPORT,
        color: colors.SEEKING_SUPPORT_EDGE,
        improvementDirection: improvementDirections.INCREASING,
      },
      {
        from: nodes.SEEKING_SUPPORT.id,
        to: nodes.HUMAN_SUPPORT_DIRECT_ENGAGED.id,
        weight: counts.humanSupportDirectEngaged,
        tooltipText: tooltipTexts.SEEKING_SUPPORT,
        color: colors.SEEKING_SUPPORT_EDGE,
        improvementDirection: improvementDirections.INCREASING,
      },
      {
        from: nodes.SELF_SERVE_ENGAGED.id,
        to: nodes.SELF_SERVE_RESOLVED.id,
        color: colors.SELF_SERVE_LINK,
        hoverColor: colors.SELF_SERVE_HOVER,
        normalColor: colors.SELF_SERVE_LINK,
        weight: counts.selfServeResolved,
        tooltipText: nodes.SELF_SERVE_ENGAGED.tooltipText,
        hoverGroup: 1,
        improvementDirection: improvementDirections.INCREASING,
      },
      {
        from: nodes.SELF_SERVE_ENGAGED.id,
        to: nodes.SELF_SERVE_FAILED.id,
        color: colors.SELF_SERVE_LINK,
        hoverColor: colors.SELF_SERVE_HOVER,
        normalColor: colors.SELF_SERVE_LINK,
        weight: counts.selfServeFailed,
        tooltipText: nodes.SELF_SERVE_ENGAGED.tooltipText,
        hoverGroup: 1,
        improvementDirection: improvementDirections.INCREASING,
      },
      {
        from: nodes.SELF_SERVE_RESOLVED.id,
        to: nodes.SELF_SERVE_FAKE_NODE.id,
        weight: counts.selfServeResolved,
        color: colors.SELF_SERVE_LINK,
        tooltipText: nodes.SELF_SERVE_FAKE_NODE.tooltipText,
        hoverGroup: 2,
        hoverColor: colors.SELF_SERVE_HOVER,
        normalColor: colors.SELF_SERVE_LINK,
        improvementDirection: improvementDirections.INCREASING,
      },
      {
        from: nodes.SELF_SERVE_FAKE_NODE.id,
        to: nodes.SELF_SERVE_PERCENTAGE.id,
        weight: counts.selfServeResolved,
        color: colors.SELF_SERVE_LINK,
        tooltipText: nodes.SELF_SERVE_FAKE_NODE.tooltipText,
        hoverGroup: 2,
        hoverColor: colors.SELF_SERVE_HOVER,
        normalColor: colors.SELF_SERVE_LINK,
        improvementDirection: improvementDirections.INCREASING,
      },
      {
        from: nodes.SELF_SERVE_FAILED.id,
        to: nodes.SELF_SERVE_FAILED_FAKE_NODE.id,
        weight: counts.selfServeFailed,
        color: colors.HUMAN_SUPPORT_LINK,
        tooltipText: nodes.SELF_SERVE_FAILED.tooltipText,
        hoverGroup: 3,
        hoverColor: colors.HUMAN_SUPPORT_HOVER,
        normalColor: colors.HUMAN_SUPPORT_LINK,
        improvementDirection: improvementDirections.DECREASING,
      },
      {
        from: nodes.SELF_SERVE_FAILED_FAKE_NODE.id,
        to: nodes.SERVED_BY_HUMAN.id,
        weight: counts.selfServeFailed,
        color: colors.HUMAN_SUPPORT_LINK,
        tooltipText: nodes.SELF_SERVE_FAILED_FAKE_NODE.tooltipText,
        hoverGroup: 3,
        hoverColor: colors.HUMAN_SUPPORT_HOVER,
        normalColor: colors.HUMAN_SUPPORT_LINK,
        improvementDirection: improvementDirections.DECREASING,
      },
      {
        from: nodes.HUMAN_SUPPORT_DIRECT_ENGAGED.id,
        to: nodes.HUMAN_SUPPORT_FAKE_NODE.id,
        weight: counts.humanSupportDirectEngaged,
        tooltipText: nodes.HUMAN_SUPPORT_DIRECT_ENGAGED.tooltipText,
        hoverGroup: 4,

        color: colors.HUMAN_SUPPORT_LINK,
        hoverColor: colors.HUMAN_SUPPORT_HOVER,
        normalColor: colors.HUMAN_SUPPORT_LINK,
        improvementDirection: improvementDirections.DECREASING,
      },
      {
        from: nodes.HUMAN_SUPPORT_FAKE_NODE.id,
        to: nodes.SERVED_BY_HUMAN.id,
        weight: counts.humanSupportDirectEngaged,
        color: colors.HUMAN_SUPPORT_LINK,
        tooltipText: nodes.HUMAN_SUPPORT_FAKE_NODE.tooltipText,
        hoverGroup: 4,
        hoverColor: colors.HUMAN_SUPPORT_HOVER,
        normalColor: colors.HUMAN_SUPPORT_LINK,
        improvementDirection: improvementDirections.DECREASING,
      },
    ];

    if (!this.args.isProactiveSetupDone) {
      data.shift();
    }

    return data;
  }

  getNodesForSankey(counts) {
    let copiedNodes = Object.values(nodes).map((node) => {
      let copiedNode = copy(node, true);
      if (!this.args.isProactiveSetupDone) {
        copiedNode.level -= 1;
      }
      if (copiedNode.id === nodes.PROACTIVE.id) {
        copiedNode.actualValue = counts.proactive;
      }

      return this._setPreviousValueOnNode(copiedNode, counts);
    });

    if (!this.args.isProactiveSetupDone) {
      copiedNodes.shift();
    }

    return copiedNodes;
  }

  get levelForSankey() {
    return this.args.isProactiveSetupDone ? 1 : 0;
  }

  _setPreviousValueOnNode(node, counts) {
    node.previousValue =
      counts[this.previousName(node.id)] || counts[this.previousName(node.originalNodeId)] || 0;
    return node;
  }

  @action
  trackAnalyticsEvent(event) {
    let point = event.point;

    // Get the corresponding node if the click is on a link
    if (point.formatPrefix === 'point') {
      point = point.fromNode;
    }

    let clickedNode = Object.values(nodes).find((node) => node.id === point.id);
    if (clickedNode) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'sankey_diagram',
        context: clickedNode.eventContext,
      });
    }
  }
}
