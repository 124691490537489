/* import __COLOCATED_TEMPLATE__ from './drill-in-preview-panel.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import Conversation from 'embercom/objects/inbox/conversation';
import { request } from 'embercom/lib/inbox/requests';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type ApplicationInstance from '@ember/application/instance';
import { registerDestructor } from '@ember/destroyable';
import type Router from '@ember/routing/router-service';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import type IntlService from 'ember-intl/services/intl';
import type Session from 'embercom/services/session';
import {
  type CopilotPromptResponsePairWireFormat,
  CopilotPromptResponsePair,
} from 'embercom/lib/inbox2/copilot/types';
import { captureException } from 'embercom/lib/sentry';

interface Args {
  onClose: () => void;
  eventPlace: string;
  isStandalone?: boolean;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [StreamResourceManager];
  };
}

type PanelResource = Conversation | CopilotPromptResponsePair;
type PanelResourceType = typeof Conversation | typeof CopilotPromptResponsePair;

export interface StreamResourceManager {
  showPreviewSideBar: (
    resourceId: number,
    resourceType: PanelResourceType,
    relatedResourceIds: number[],
  ) => TaskGenerator<void>;
  showConversationSideBarAndHighlightPart: (
    selectedConversationId: number,
    selectedPartId: number,
    conversationIds: number[],
  ) => TaskGenerator<void>;
}

export default class DrillInPreviewPanel extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: any;

  @tracked activeResource?: PanelResource = undefined;
  @tracked activeResourceType?: PanelResourceType = undefined;
  @tracked activeResourceId?: number = undefined;
  @tracked metadata?: any = undefined;
  @tracked relatedResourceIds: number[] = [];

  private cachedResources: Record<string, PanelResource> = {};

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    registerDestructor(this, () => {
      this.cachedResources = {};
    });
  }

  get conversation(): Conversation | undefined {
    if (!(this.activeResource instanceof Conversation)) {
      return;
    }

    return this.activeResource;
  }

  get copilotPromptResponsePair(): CopilotPromptResponsePair | undefined {
    if (!(this.activeResource instanceof CopilotPromptResponsePair)) {
      return;
    }

    return this.activeResource;
  }

  private getFromCache(
    resourceId: number,
    resourceType: PanelResourceType,
  ): PanelResource | undefined {
    return this.cachedResources[`${resourceType.name}/${resourceId}`];
  }

  private addToCache(resourceId: number, resource: PanelResource) {
    this.cachedResources[`${resource.constructor.name}/${resourceId}`] = resource;
  }

  get app() {
    return this.appService.app;
  }

  get currentStreamPage() {
    return this.currentResourceIndex + 1;
  }

  get sideDrawerTitle() {
    if (this.activeResourceType === Conversation) {
      if (this.conversation) {
        if (this.conversation.ticketCategory === 'tracker') {
          return this.intl.t(
            'components.reporting.custom.report.conversation-stream.tracker-ticket',
          );
        } else {
          return this.activeResource !== undefined
            ? this.conversation.firstParticipant.displayAs
            : '';
        }
      } else {
        return this.intl.t(
          'components.reporting.custom.report.conversation-stream.sidedrawer-title',
        );
      }
    } else {
      return this.intl.t(
        'components.reporting.custom.report.assistant-conversation-stream.sidedrawer-title',
      );
    }
  }

  get summary() {
    return this.intl.t('reporting.conversation-stream.summary', {
      currentStreamPage: this.intl.formatNumber(this.currentStreamPage),
      totalStreams: this.intl.formatNumber(this.relatedResourceIds.length),
    });
  }

  get showPreviewPanel() {
    return taskFor(this.changeActiveResource).isRunning || isPresent(this.activeResource);
  }

  get isNotLoadingResource() {
    return !taskFor(this.changeActiveResource).isRunning;
  }

  @restartableTask
  private *changeActiveResource(resourceId: number) {
    let cachedResource = this.getFromCache(resourceId, this.activeResourceType!);
    if (cachedResource) {
      this.activeResource = cachedResource;
      this.activeResourceId = resourceId;
      return;
    }

    yield taskFor(this.fetchAndActivateResource).perform(resourceId);
  }

  @restartableTask
  private *fetchAndActivateResource(resourceId: number) {
    let resource: PanelResource;
    if (this.activeResourceType === Conversation) {
      resource = (yield this.inboxApi.fetchConversation(resourceId)) as Conversation;
    } else {
      let response = (yield request(
        `/ember/inbox/copilot_conversations/prompt_response_pair?prompt_part_id=${resourceId}&app_id=${this.session.workspace.id}`,
      )) as Response;
      let json = (yield response.json()) as CopilotPromptResponsePairWireFormat;
      resource = CopilotPromptResponsePair.deserialize(json);
    }

    this.addToCache(resourceId, resource);
    this.activeResource = resource;
    this.activeResourceId = resourceId;
  }

  @restartableTask
  *showPreviewSideBar(
    resourceId: number,
    resourceType: PanelResourceType,
    relatedResourceIds: number[],
  ): TaskGenerator<void> {
    this.activeResourceType = resourceType;
    this.relatedResourceIds = relatedResourceIds.uniq();

    yield taskFor(this.changeActiveResource).perform(resourceId);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'open',
      object: 'data_table_conversation_stream_link',
      place: this.args.eventPlace,
      resource_type: this.resourceTypeName(resourceType),
    });
  }

  @restartableTask
  *showConversationSideBarAndHighlightPart(
    selectedConversationId: number,
    selectedPartId: number,
    conversationIds: number[],
  ) {
    window.location.hash = `part_id=comment-${selectedConversationId}-${selectedPartId}`;
    // we need to update hash before tracked property
    this.metadata = { partId: selectedPartId };
    yield taskFor(this.showPreviewSideBar).perform(
      selectedConversationId,
      Conversation,
      conversationIds,
    );
  }

  private resourceTypeName(resourceType: PanelResourceType): string {
    switch (resourceType) {
      case Conversation:
        return 'conversation';
      case CopilotPromptResponsePair:
        return 'copilot_prompt_response_pair';
      default:
        captureException(new Error('Unknown resourece type'), {
          fingerprint: ['copilot-reporting', 'unknown-resource-type'],
          extra: { resourceType },
        });
        return 'unknown';
    }
  }

  private removeLocationHash() {
    let noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', '', noHashURL);
  }

  @action
  onClose() {
    this.activeResource = undefined;
    this.activeResourceType = undefined;
    this.activeResourceId = undefined;
    this.metadata = undefined;
    this.removeLocationHash();
    this.args.onClose();
  }

  get currentResourceIndex() {
    if (this.activeResourceId) {
      return this.relatedResourceIds.indexOf(this.activeResourceId);
    }
    return 0;
  }

  get hasPrev() {
    return this.currentResourceIndex > 0;
  }

  get hasNext() {
    return this.currentResourceIndex < this.relatedResourceIds.length - 1;
  }

  @restartableTask
  *onNext() {
    if (this.hasNext) {
      let nextId = this.relatedResourceIds[this.currentResourceIndex + 1];
      yield taskFor(this.changeActiveResource).perform(nextId);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'data_table_conversation_stream_navigation',
        place: this.args.eventPlace,
      });
    }
  }

  @restartableTask
  *onPrev() {
    if (this.hasPrev) {
      let nextId = this.relatedResourceIds[this.currentResourceIndex - 1];
      yield taskFor(this.changeActiveResource).perform(nextId);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'data_table_conversation_stream_navigation',
        place: this.args.eventPlace,
      });
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::DrillInPreviewPanel': typeof DrillInPreviewPanel;
    'reporting/custom/report/drill-in-preview-panel': typeof DrillInPreviewPanel;
  }
}
