/* import __COLOCATED_TEMPLATE__ from './report-list-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type ViewIdStrings, type ViewItem } from 'embercom/services/reporting-views';
import Admin from 'embercom/models/admin';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { modifier } from 'ember-modifier';
import type Store from '@ember-data/store';
import type ReportingViews from 'embercom/services/reporting-views';
import type Router from '@ember/routing/router-service';

interface Args {
  reports: ViewItem[];
  shouldShowSearch: boolean;
  reportsTab: string;
  newReport: () => void;
}
type SortDirection = 'asc' | 'desc';

interface Signature {
  Args: Args;
  Element: HTMLElement;
}
const PAGE_SIZE = 50;
const SIZE_INCREASE = 20;

export default class ReportListTable extends Component<Signature> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare reportingViews: ReportingViews;
  @service declare router: Router;

  @tracked searchTerm: string | undefined;
  lastURL: string | undefined;

  @tracked sortState: any = this.defaultSortState(this.args.reportsTab);
  @tracked scrollMoreCount = 0;
  @tracked selectedOwner = 'any';

  @action
  async findTeammate(teammateId: string) {
    return Admin.peekAndMaybeLoad(this.store, teammateId);
  }

  resetOnUrlChange = modifier(
    (_element: Element, [currentURL]: [string]) => {
      if (this.lastURL !== currentURL) {
        this.resetSearch();
        this.scrollMoreCount = 0;
        this.selectedOwner = 'any';
        this.sortState = this.defaultSortState(this.args.reportsTab);
        this.lastURL = currentURL;
      }
    },
    { eager: false },
  );

  get data() {
    let { direction, valuePath } = this.sortState;
    let reports =
      direction === 'asc'
        ? this.filteredAndPaginatedReports.sortBy(valuePath)
        : this.filteredAndPaginatedReports.sortBy(valuePath).reverse();
    return reports;
  }

  get columns() {
    let columns = [
      {
        valuePath: 'name',
        label: this.intl.t('reporting.custom-reports.report.table.title'),
        isSortable: true,
        width: '55%',
        maxWidth: '55%',
      },
      {
        valuePath: 'author.name',
        label: this.intl.t('reporting.custom-reports.report.table.owned-by'),
        isSortable: true,
        width: '15%',
      },
      {
        valuePath: 'updatedAt',
        label: this.intl.t('reporting.custom-reports.report.table.updated-at'),
        isSortable: true,
        width: '15%',
      },
      {
        valuePath: 'updatedBy.name',
        label: this.intl.t('reporting.custom-reports.report.table.updated-by'),
        isSortable: true,
        width: '15%',
      },
    ];
    return columns;
  }

  private get filteredAndPaginatedReports(): ViewItem[] {
    if ((this.searchTerm && this.searchTerm.length > 0) || this.selectedOwner !== 'any') {
      let untitledReportName = this.intl.t('reporting.custom-reports.report.untitled');
      let filteredReports = this.args.reports.filter((report: any) => {
        let reportName = report.name || untitledReportName;
        return reportName.toLocaleLowerCase().includes(this.searchTerm);
      });
      if (this.selectedOwner !== 'any') {
        filteredReports = filteredReports.filter(
          (report) => report.author?.id === this.selectedOwner,
        );
      }
      return filteredReports.slice(0, this.currentPageLength);
    } else {
      return this.args.reports.slice(0, this.currentPageLength);
    }
  }

  get currentPageLength() {
    return Math.min(PAGE_SIZE + this.scrollMoreCount * SIZE_INCREASE, this.args.reports.length);
  }

  @action
  onLoadMore() {
    this.scrollMoreCount += 1;
  }

  @action
  onSort(clickedValuePath: string) {
    let oldSortDirection = this.sortState.direction;
    let newSortDirection: SortDirection = oldSortDirection === 'asc' ? 'desc' : 'asc';
    this.sortState = {
      valuePath: clickedValuePath,
      direction: newSortDirection,
    };
    this.scrollMoreCount = 0;
  }

  @action
  doSearch(item: any) {
    this.searchTerm = item.target.value.toLocaleLowerCase();
    this.scrollMoreCount = 0;
  }

  @action
  resetSearch() {
    this.searchTerm = '';
  }

  @action
  onSelectionChange(selected: any) {
    this.router.transitionTo('apps.app.reports.views.view', selected, {
      queryParams: { isSwitchingTabs: true },
    });
  }

  findReports(tabId: string) {
    return this.reportingViews.reportTabs.find((tab) => tab.id === tabId)?.reports || [];
  }

  get reportOwners() {
    return this.reportingViews.sharedReports
      .map((report) => report.author)
      .uniq()
      .sortBy('display_as_assignee');
  }

  @action
  filterByOwner(owner: any) {
    this.selectedOwner = owner;
  }

  get ownerFilterlabel() {
    if (this.selectedOwner === 'any') {
      return this.intl.t('reporting.custom-reports.report.table.owner-is', {
        owner: this.intl.t('reporting.custom-reports.chart.sidebar-filters.visualizations.any'),
        htmlSafe: true,
      });
    }
    return this.intl.t('reporting.custom-reports.report.table.owner-is', {
      owner: this.allItems.find((obj) => obj?.value === this.selectedOwner)?.text,
      htmlSafe: true,
    });
  }

  get filterItems() {
    return [
      {
        text: this.intl.t('reporting.custom-reports.chart.sidebar-filters.visualizations.any'),
        value: 'any',
        icon: 'chart',
      },
      {
        heading: this.intl.t('reporting.custom-reports.report.table.teammate'),
        items: this.allItems,
      },
    ];
  }

  get allItems() {
    return [
      ...this.reportOwners.map((teammate) => ({
        model: teammate,
        text: teammate?.name,
        value: teammate?.id?.toString(),
        component: 'reporting/flexible/filter-bar/teammate-item',
        isSelected: teammate?.id ? this.selectedOwner.includes(teammate.id.toString()) : false,
      })),
    ];
  }

  defaultSortState(selectedTab: string) {
    if (selectedTab === 'intercom-reports') {
      return {
        valuePath: 'name',
        direction: 'asc',
      };
    }
    return {
      valuePath: 'updatedAt',
      direction: 'desc',
    };
  }

  @action
  getCount(viewId: ViewIdStrings) {
    return this.reportingViews.reportTabs.findBy('id', viewId)!.reports.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Views::ReportListTable': typeof ReportListTable;
  }
}
