/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { task } from 'ember-concurrency-decorators';

export default class SeriesSettingsComponent extends Component {
  @service seriesEditorService;

  seriesEntityType = objectTypes.series;

  @action
  onSettingsClose() {
    this.seriesEditorService.updateIsShowingSettings(false);
  }

  @action
  enableEditMode() {
    this.seriesEditorService.changeMode('edit');
  }

  defaultGoalTimeConstraint = 2160;

  @task
  *saveChanges() {
    yield this.seriesEditorService.updateSettings.perform();
    this.seriesEditorService.updateIsShowingSettings(false);
  }

  @task
  *cancelChanges() {
    yield this.seriesEditorService.cancelSettingsChanges.perform();
    this.seriesEditorService.updateIsShowingSettings(false);
  }
}
