/* import __COLOCATED_TEMPLATE__ from './view-mode.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import HeaderComponent from 'embercom/components/series/header';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { statisticKeys } from 'embercom/models/data/outbound/constants';

export default class ViewMode extends HeaderComponent {
  @service appService;
  @service seriesEditorService;
  @service notificationsService;
  @service intercomEventService;
  @service permissionsService;

  @tracked showDeleteSeriesModal = false;
  @tracked showCsvExportModal = false;
  @tracked showTaggingModal = false;

  get series() {
    return this.seriesEditorService.series;
  }

  get isCsvExportDisabled() {
    let seriesReceiptStats = this.seriesEditorService.series.stats.findBy(
      'key',
      statisticKeys.receipts,
    );
    return !(seriesReceiptStats && seriesReceiptStats.value);
  }

  @action
  enableEdit() {
    this.seriesEditorService.changeMode('edit');
  }

  @action
  openDeleteSeriesModal() {
    this.showDeleteSeriesModal = true;
  }

  @action
  closeDeleteSeriesModal() {
    this.showDeleteSeriesModal = false;
  }

  @action
  openSettings() {
    this.seriesEditorService.storeSettingsState();
    this.seriesEditorService.updateIsShowingSettings(true);
  }

  @action
  showCsvExport() {
    let hasPermission = this.permissionsService.currentAdminCan('can_export_outbound_data');
    if (hasPermission) {
      this.showCsvExportModal = true;
    } else {
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_export_outbound_data',
      );
    }
  }
}
