/* import __COLOCATED_TEMPLATE__ from './chart-action-options.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type Chart from 'embercom/models/reporting/custom/chart';
import { inject as service } from '@ember/service';
import type ReportingCsvExport from 'embercom/services/reporting-csv-export';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type Report from 'embercom/models/reporting/custom/report';
import type IntlService from 'ember-intl/services/intl';
import type { InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export type State = 'new' | 'legacy' | 'beta';

export enum ActionType {
  DRILL_IN,
  AGGREGATE_DATA,
  DOWNLOAD_CSV_SOURCE,
  VIEW_SOURCE,
  EDIT_CHART,
  DUPLICATE_CHART,
  DELETE_CHART,
}

export interface ExploreOption {
  name: string;
  icon: InterfaceIconName;
  state?: State;
  tooltipText?: string;
  actionType: ActionType;
  isDisabled?: boolean;
  disabledTooltipText: string;
  classList?: string;
}

export type StampColor =
  | 'gray'
  | 'green'
  | 'yellow-light'
  | 'sky'
  | 'blue'
  | 'red'
  | 'transparent'
  | 'yellow';

interface Args {
  exploreOption: ExploreOption;
  chart: Chart;
  renderableChart: RenderableChart;
  report: Report | null;
  paywall?: any;
  onClick: () => void;
}

export default class ChartActionOptions extends Component<Args> {
  @service declare reportingCsvExport: ReportingCsvExport;
  @service declare intl: IntlService;

  get isDrillIn() {
    return this.args.exploreOption.actionType === ActionType.DRILL_IN;
  }

  get isReportAction() {
    return (
      this.args.exploreOption.actionType === ActionType.EDIT_CHART ||
      this.args.exploreOption.actionType === ActionType.DELETE_CHART
    );
  }

  get isDuplicateChartAction() {
    return this.args.exploreOption.actionType === ActionType.DUPLICATE_CHART;
  }

  get actionParameter() {
    if (this.isDuplicateChartAction) {
      return { chartId: this.args.chart.id, description: this.args.renderableChart.description };
    }

    return this.isReportAction ? this.args.chart.id : this.args.chart.chartSeries.firstObject;
  }

  get stamp(): { text: string; color: StampColor } {
    switch (this.args.exploreOption.state) {
      case 'new':
        return { text: this.intl.t('reporting.stamps.new'), color: 'blue' };
      case 'legacy':
        return { text: this.intl.t('reporting.stamps.legacy'), color: 'yellow-light' };
      case 'beta':
        return { text: this.intl.t('reporting.stamps.beta'), color: 'sky' };
      default:
        return { text: '', color: 'transparent' };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartActions::ChartActionOptions': typeof ChartActionOptions;
    'reporting/custom/chart-actions/chart-action-options': typeof ChartActionOptions;
  }
}
