/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  icon?: InterfaceIconName;
  title: string;
  description?: string;
  classNames?: string;
}

const EmptyState = templateOnlyComponent<Args>();
export default EmptyState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::EmptyState': typeof EmptyState;
    'reporting/custom/empty-state': typeof EmptyState;
  }
}
